import { filterDOMProps, useObjectRef } from '@react-aria/utils';
import type { DOMProps } from '@react-types/shared';
import { type ReactNode, type ForwardedRef, forwardRef } from 'react';
import {
  type AriaPopoverProps,
  DismissButton,
  Overlay,
  usePopover,
} from 'react-aria';
import {
  type DialogProps,
  Modal,
  Dialog,
  ModalOverlay,
} from 'react-aria-components';
import type { OverlayTriggerState } from 'react-stately';

import { type StyleProps, cn, useIsSmallScreen } from '../../utils';

type TrayConfig = {
  dialogProps?: DialogProps;
  fixedHeight?: boolean;
};
export type BigComboboxPopoverProps = Omit<AriaPopoverProps, 'popoverRef'> & {
  children: ReactNode;
  /**
   * When provided, the popover will render as a tray on small screens.
   */
  trayConfig?: TrayConfig;
  state: OverlayTriggerState;
} & DOMProps &
  StyleProps;

export const BigComboboxPopover = forwardRef(function BigComboboxPopover(
  props: BigComboboxPopoverProps,
  forwardedRef: ForwardedRef<HTMLDivElement>
) {
  const {
    children,
    className,
    trayConfig,
    isNonModal,
    state,
    style,
    ...otherProps
  } = props;

  const isSmallScreen = useIsSmallScreen();

  if (isSmallScreen && trayConfig) {
    return (
      <ModalOverlay
        isDismissable
        onOpenChange={state.setOpen}
        isOpen={state.isOpen}
        className={cn(
          'z-dialog bg-blanket fixed left-0 top-0 flex w-screen flex-col items-stretch justify-end backdrop-blur',
          'entering:animate-in entering:fade-in entering:duration-300 entering:ease-out',
          'exiting:animate-out exiting:fade-out exiting:duration-200 exiting:ease-in'
        )}
        style={{
          height: 'var(--visual-viewport-height)',
        }}
      >
        <Modal
          {...filterDOMProps(otherProps)}
          className={cn(
            'bg-shark3 overflow-hidden rounded-t-md shadow-lg outline-none backdrop-blur-lg backdrop-filter',
            // animations
            'entering:animate-in entering:slide-in-from-bottom entering:duration-300 entering:ease-out',
            'exiting:animate-out exiting:slide-out-to-bottom exiting:duration-200 exiting:ease-in'
          )}
        >
          <Dialog
            {...trayConfig.dialogProps}
            ref={forwardedRef}
            style={{
              height: trayConfig.fixedHeight
                ? 'calc(var(--visual-viewport-height) - 44px)'
                : undefined,
              // always define max-height so it can be inherited
              maxHeight: 'calc(var(--visual-viewport-height) - 44px)',
            }}
          >
            {children}
          </Dialog>
        </Modal>
      </ModalOverlay>
    );
  }

  return state.isOpen && <Popover {...props}>{children}</Popover>;
});

const Popover = forwardRef(function BigComboboxPopover(
  props: BigComboboxPopoverProps,
  forwardedRef: ForwardedRef<HTMLDivElement>
) {
  const { children, className, isNonModal, state, style, ...otherProps } =
    props;

  const popoverRef = useObjectRef<HTMLDivElement>(forwardedRef);
  const { popoverProps, underlayProps, placement } = usePopover(
    { ...otherProps, popoverRef, isNonModal },
    state
  );

  return (
    <Overlay>
      {!isNonModal && <div {...underlayProps} className="fixed inset-0" />}
      <div
        {...popoverProps}
        data-placement={placement}
        ref={popoverRef}
        className={cn(
          'bg-shark3 my-2 flex flex-col overflow-hidden rounded-md border shadow-lg outline-none backdrop-blur-lg backdrop-filter',
          // animations
          'animate-in fade-in slide-in-from-top-2 ease-out',
          className
        )}
        style={{ ...popoverProps.style, ...style }}
      >
        {!isNonModal && <DismissButton onDismiss={state.close} />}

        {children}

        <DismissButton onDismiss={state.close} />
      </div>
    </Overlay>
  );
});
