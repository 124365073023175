import {
  ChainAsset,
  ChainAssetId,
  ChainKey,
  Environment,
  EvmChainKey,
  SolanaChainKey,
} from '@infinex/asset-config';
import { Connection, PublicKey } from '@solana/web3.js';
import {
  type Address,
  Chain,
  createPublicClient,
  http,
  HttpTransport,
} from 'viem';
import {
  arbitrum,
  arbitrumSepolia,
  base,
  baseSepolia,
  blast,
  blastSepolia,
  mainnet,
  optimism,
  optimismSepolia,
  polygon,
  polygonAmoy,
  sepolia,
} from 'viem/chains';

import { getBalances } from './getBalances';
import { getDecimals } from './getDecimals';
import { getTokenMetadata } from './getMetadata';
import { CustomAssetLookup, EvmClient } from './types';

export { getEvmTokenDecimals } from './getDecimals/getEvmDecimals';
export { getSolanaDecimals } from './getDecimals/getSolanaDecimals';

type ClientConfig = {
  env: Environment;
  chains?: {
    [Chain in ChainKey]: {
      rpcUrl?: Chain extends 'solana' ? string | Connection : string;
    };
  };
  pollingInterval?: number;
  chainAssetOverrides?: Record<ChainAssetId, Partial<ChainAsset>>;
};

// TODO: Better name, different spot?
export const getChainClustersFromEnv = (env: Environment) => {
  const isProdOrStaging = env === 'prod' || env === 'staging';
  return {
    arbitrum: isProdOrStaging ? arbitrum : arbitrumSepolia,
    base: isProdOrStaging ? base : baseSepolia,
    blast: isProdOrStaging ? blast : blastSepolia,
    ethereum: isProdOrStaging ? mainnet : sepolia,
    optimism: isProdOrStaging ? optimism : optimismSepolia,
    polygon: isProdOrStaging ? polygon : polygonAmoy,
    solana: isProdOrStaging
      ? 'https://api.mainnet-beta.solana.com'
      : 'https://api.devnet.solana.com',
  };
};

export function createClient(config: ClientConfig) {
  const chainClusters = getChainClustersFromEnv(config.env);
  const rpcClients: Record<EvmChainKey, EvmClient> &
    Record<SolanaChainKey, Connection> = {
    arbitrum: getRpcClient(config, 'arbitrum'),
    base: getRpcClient(config, 'base'),
    blast: getRpcClient(config, 'blast'),
    ethereum: getRpcClient(config, 'ethereum'),
    optimism: getRpcClient(config, 'optimism'),
    polygon: getRpcClient(config, 'polygon'),
    solana:
      // `instanceof Connection` may not always work if versions differ
      typeof config.chains?.solana.rpcUrl === 'object' &&
      'getBlock' in config.chains.solana.rpcUrl
        ? config.chains?.solana.rpcUrl
        : new Connection(
            config.chains?.solana.rpcUrl ?? chainClusters.solana,
            'confirmed'
          ),
  };

  return {
    async getDecimals({ chainAssets }: { chainAssets: ChainAsset[] }) {
      const decimalMap = await getDecimals({
        rpcClients,
        assets: chainAssets,
      });
      return decimalMap;
    },
    async getMetadata({
      assetLookups,
      heliusApiUrl,
    }: {
      assetLookups: CustomAssetLookup[];
      heliusApiUrl: string;
    }) {
      return await getTokenMetadata({
        rpcClients,
        heliusApiUrl,
        assetLookups,
      });
    },
    async getBalances({
      evmAccountAddress,
      solanaAccountAddress,
      supportedChainAssets,
    }: {
      evmAccountAddress?: Address;
      solanaAccountAddress?: string | PublicKey;
      supportedChainAssets: ChainAsset[];
    }) {
      const balances = await getBalances({
        solanaAccountAddress,
        evmAccountAddress,
        rpcClients,
        chainAssets: supportedChainAssets,
      });

      return balances;
    },
  };
}

function getRpcClient(config: ClientConfig, chain: EvmChainKey): EvmClient {
  const chainClusters = getChainClustersFromEnv(config.env);
  // We explicitly provide types here to avoid slow inference time
  // @see `Client` type in `types/index.ts`
  return createPublicClient<HttpTransport, undefined, undefined, undefined>({
    batch: { multicall: true },
    chain: chainClusters[chain] as Chain,
    transport: http(config.chains?.[chain].rpcUrl),
  }) as unknown as EvmClient;
}
