import { ChainAsset, ChainAssetId, Environment } from '@infinex/asset-config';
import { generateCompressedKeyPair } from '@infinex/crypto';
import type {
  AccountsAppRouter,
  ActionRouter,
  AirdropRouter,
  AssetRouter,
  AuthRouter,
  BridgeRouter,
  CardrunRouter,
  ContractRouter,
  CurveRouter,
  HealthRouter,
  PatronClaimRouter,
  PatronRouter,
  RecoveryRouter,
  StatsRouter,
  SwidgeRouter,
  WithdrawRouter,
} from '@infinex/main-service/types';
import { createClient as createPublicClient } from '@infinex/public-client';
import { Event } from '@infinex/types';
import {
  CreateTRPCProxyClient,
  createTRPCProxyClient,
  createWSClient,
  httpBatchLink,
  HTTPBatchLinkOptions,
  httpLink,
  splitLink,
  wsLink,
} from '@trpc/client';
import { AnyRouter, inferRouterInputs, inferRouterOutputs } from '@trpc/server';
import { Unsubscribable } from '@trpc/server/observable';
import { createActivityPoller, TurnkeyClient } from '@turnkey/http';
import { WebauthnStamper } from '@turnkey/webauthn-stamper';
import IsomorphicWebSocket from 'isomorphic-ws';

const NON_BATCH_PATHS = ['cardrunGetRealtimePlayChart', 'cardrunGetPlayChart'];

/**
 * We declare this type upfront to avoid slow type inference.
 *
 * TODO: investigate generating _some_ of this automatically. Be conscious of slow
 * type inference.
 **/
export type Client = {
  /**
   * Health
   **/
  healthCheck: () => Promise<{
    api: PromiseSettledResult<unknown>;
    subscriptions: PromiseSettledResult<unknown>;
    durableWorkflows: PromiseSettledResult<unknown>;
  }>;
  /**
   * Auth
   **/
  signup: (
    input: inferRouterInputs<AuthRouter>['signup']
  ) => Promise<inferRouterOutputs<AuthRouter>['signup']>;
  login: (
    input: inferRouterInputs<AuthRouter>['login']
  ) => Promise<inferRouterOutputs<AuthRouter>['login']>;
  logout: () => Promise<void>;
  generateRegistrationOptions: (
    userName: string
  ) => Promise<inferRouterOutputs<AuthRouter>['generateRegistrationOptions']>;
  generateAuthenticationOptions: (
    userName: string
  ) => Promise<inferRouterOutputs<AuthRouter>['generateAuthenticationOptions']>;
  createSessionKey: (opts?: { stamper?: WebauthnStamper }) => Promise<void>;
  requiresSessionKey: () => Promise<void>;
  getSessionKeyExpiry: () => Promise<
    inferRouterOutputs<AuthRouter>['getSessionKeyExpiry']
  >;
  updateUserEmail: (
    email: string
  ) => Promise<inferRouterOutputs<AuthRouter>['updateUserEmail']>;
  verifyUserEmail: (
    verificationCode: string
  ) => Promise<inferRouterOutputs<AuthRouter>['verifyUserEmail']>;
  getCurrentUser: () => Promise<
    inferRouterOutputs<AuthRouter>['getCurrentUser']
  >;
  getUsernameSuggestions: () => Promise<
    inferRouterOutputs<AuthRouter>['getUsernameSuggestions']
  >;
  checkUsernameAvailability: (
    input: inferRouterInputs<AuthRouter>['checkUsernameAvailability']
  ) => Promise<inferRouterOutputs<AuthRouter>['checkUsernameAvailability']>;
  checkReferralCodeValidity: (
    input: inferRouterInputs<AuthRouter>['checkReferralCodeValidity']
  ) => Promise<inferRouterOutputs<AuthRouter>['checkReferralCodeValidity']>;
  createSupportTicket: (
    input: inferRouterInputs<AuthRouter>['createSupportTicket']
  ) => Promise<inferRouterOutputs<AuthRouter>['createSupportTicket']>;
  oauth: {
    twitterGenerateOAuthLink: () => Promise<
      inferRouterOutputs<AuthRouter>['twitterGenerateOAuthLink']
    >;
    twitterOAuthToken: (
      input: inferRouterInputs<AuthRouter>['twitterOAuthToken']
    ) => Promise<inferRouterOutputs<AuthRouter>['twitterOAuthToken']>;
  };
  totp: {
    makeTotp: (
      input: inferRouterInputs<AuthRouter>['makeTotp']
    ) => Promise<inferRouterOutputs<AuthRouter>['makeTotp']>;
    enrolTotp: (
      input: inferRouterInputs<AuthRouter>['enrolTotp']
    ) => Promise<inferRouterOutputs<AuthRouter>['enrolTotp']>;
    authTotp: (
      input: inferRouterInputs<AuthRouter>['authTotp']
    ) => Promise<inferRouterOutputs<AuthRouter>['authTotp']>;
  };
  /**
   * Stats
   **/
  getLeaderboardStats: () => Promise<
    inferRouterOutputs<StatsRouter>['getLeaderboardStats']
  >;
  getPlatformStats: () => Promise<
    inferRouterOutputs<StatsRouter>['getPlatformStats']
  >;
  getWaitlistValues: () => Promise<
    inferRouterOutputs<StatsRouter>['getWaitlistValues']
  >;
  ctpGetValues: (
    input: inferRouterInputs<StatsRouter>['ctpGetValues']
  ) => Promise<inferRouterOutputs<StatsRouter>['ctpGetValues']>;
  /**
   * Contract
   **/
  addRecoveryKey: (
    input: inferRouterInputs<ContractRouter>['addRecoveryKey']
  ) => Promise<inferRouterOutputs<ContractRouter>['addRecoveryKey']>;
  addFundsRecoveryAddress: (
    input: inferRouterInputs<ContractRouter>['addFundsRecoveryAddress']
  ) => Promise<inferRouterOutputs<ContractRouter>['addFundsRecoveryAddress']>;
  upgradeEvm: (
    input: inferRouterInputs<ContractRouter>['upgradeEvm']
  ) => Promise<inferRouterOutputs<ContractRouter>['upgradeEvm']>;
  upgradeSolana: (
    input: inferRouterInputs<ContractRouter>['upgradeSolana']
  ) => Promise<inferRouterOutputs<ContractRouter>['upgradeSolana']>;
  deployEvmAppAccount: (
    input: inferRouterInputs<ContractRouter>['deployEvmAppAccount']
  ) => Promise<inferRouterOutputs<ContractRouter>['deployEvmAppAccount']>;
  getUpgrades: () => Promise<inferRouterOutputs<ContractRouter>['getUpgrades']>;
  getEvmAppAccounts: () => Promise<
    inferRouterOutputs<ContractRouter>['getEvmAppAccounts']
  >;

  getSupportedChainAssets: () => Promise<
    inferRouterOutputs<AssetRouter>['getSupportedChainAssets']
  >;
  getUserBalances: () => Promise<
    inferRouterOutputs<AssetRouter>['getUserBalances']
  >;

  getAssetRates: () => Promise<
    inferRouterOutputs<AssetRouter>['getAssetRates']
  >;
  refreshAssetRates: () => Promise<
    inferRouterOutputs<AssetRouter>['refreshAssetRates']
  >;
  syncAssetConfig: () => Promise<
    inferRouterOutputs<AssetRouter>['syncAssetConfig']
  >;
  /**
   * Withdraw
   **/
  withdrawEvm: (
    input: inferRouterInputs<WithdrawRouter>['withdrawEvm']
  ) => Promise<inferRouterOutputs<WithdrawRouter>['withdrawEvm']>;
  withdrawEvmNft: (
    input: inferRouterInputs<WithdrawRouter>['withdrawEvmNft']
  ) => Promise<inferRouterOutputs<WithdrawRouter>['withdrawEvmNft']>;
  withdrawSolana: (
    input: inferRouterInputs<WithdrawRouter>['withdrawSolana']
  ) => Promise<inferRouterOutputs<WithdrawRouter>['withdrawSolana']>;
  getWithdrawals: () => Promise<
    inferRouterOutputs<WithdrawRouter>['getWithdrawals']
  >;
  getWithdrawalAddresses: () => Promise<
    inferRouterOutputs<WithdrawRouter>['getWithdrawalAddresses']
  >;
  deleteWithdrawalAddress: (
    input: inferRouterInputs<WithdrawRouter>['deleteWithdrawalAddress']
  ) => Promise<inferRouterOutputs<WithdrawRouter>['deleteWithdrawalAddress']>;
  updateWithdrawalAddress: (
    input: inferRouterInputs<WithdrawRouter>['updateWithdrawalAddress']
  ) => Promise<inferRouterOutputs<WithdrawRouter>['updateWithdrawalAddress']>;
  addWithdrawalAddress: (
    input: inferRouterInputs<WithdrawRouter>['addWithdrawalAddress']
  ) => Promise<inferRouterOutputs<WithdrawRouter>['addWithdrawalAddress']>;
  /**
   * Bridge
   **/
  bridge: (
    input: inferRouterInputs<BridgeRouter>['bridge']
  ) => Promise<inferRouterOutputs<BridgeRouter>['bridge']>;
  getBridges: () => Promise<inferRouterOutputs<BridgeRouter>['getBridges']>;
  /**
   * Curve
   **/
  curveSwap: (
    input: inferRouterInputs<CurveRouter>['curveSwap']
  ) => Promise<inferRouterOutputs<CurveRouter>['curveSwap']>;
  curveQuote: (
    input: inferRouterInputs<CurveRouter>['curveQuote']
  ) => Promise<inferRouterOutputs<CurveRouter>['curveQuote']>;
  curveGetPools: (
    input: inferRouterInputs<CurveRouter>['curveGetPools']
  ) => Promise<inferRouterOutputs<CurveRouter>['curveGetPools']>;
  curveGetSwaps: (
    input: inferRouterInputs<CurveRouter>['curveGetSwaps']
  ) => Promise<inferRouterOutputs<CurveRouter>['curveGetSwaps']>;
  /**
   * Cardrun
   *
   * TODO: move under `cardrun` namespace
   **/
  cardrunGetRounds: () => Promise<
    inferRouterOutputs<CardrunRouter>['cardrunGetRounds']
  >;
  cardrunGetMinimumBalance: () => Promise<
    inferRouterOutputs<CardrunRouter>['cardrunGetMinimumBalance']
  >;
  cardrunGetStagedPlay: (
    input: inferRouterInputs<CardrunRouter>['cardrunGetStagedPlay']
  ) => Promise<inferRouterOutputs<CardrunRouter>['cardrunGetStagedPlay']>;
  cardrunOpenPack: (
    input: inferRouterInputs<CardrunRouter>['cardrunOpenPack']
  ) => Promise<inferRouterOutputs<CardrunRouter>['cardrunOpenPack']>;
  cardrunPlayRound: (
    input: inferRouterInputs<CardrunRouter>['cardrunPlayRound']
  ) => Promise<inferRouterOutputs<CardrunRouter>['cardrunPlayRound']>;
  cardrunGetPlayChart: (
    input: inferRouterInputs<CardrunRouter>['cardrunGetPlayChart']
  ) => Promise<inferRouterOutputs<CardrunRouter>['cardrunGetPlayChart']>;
  cardrunGetRealtimePlayChart: (
    input: inferRouterInputs<CardrunRouter>['cardrunGetRealtimePlayChart']
  ) => Promise<
    inferRouterOutputs<CardrunRouter>['cardrunGetRealtimePlayChart']
  >;
  cardrunLockCard: (
    input: inferRouterInputs<CardrunRouter>['cardrunLockCard']
  ) => Promise<inferRouterOutputs<CardrunRouter>['cardrunLockCard']>;
  cardrunGetRoundRealtimeRates: (
    input: inferRouterInputs<CardrunRouter>['cardrunGetRoundRealtimeRates']
  ) => Promise<
    inferRouterOutputs<CardrunRouter>['cardrunGetRoundRealtimeRates']
  >;
  cardrunGetUserStatistics: (
    input: inferRouterInputs<CardrunRouter>['cardrunGetUserStatistics']
  ) => Promise<inferRouterOutputs<CardrunRouter>['cardrunGetUserStatistics']>;
  cardrunGetLatestRoundPlays: (
    input: inferRouterInputs<CardrunRouter>['cardrunGetLatestRoundPlays']
  ) => Promise<inferRouterOutputs<CardrunRouter>['cardrunGetLatestRoundPlays']>;
  cardrunGetRoundRealtimeLeaderboard: (
    input: inferRouterInputs<CardrunRouter>['cardrunGetRoundRealtimeLeaderboard']
  ) => Promise<
    inferRouterOutputs<CardrunRouter>['cardrunGetRoundRealtimeLeaderboard']
  >;
  cardrunGetRoundGroupRealtimeLeaderboard: (
    input: inferRouterInputs<CardrunRouter>['cardrunGetRoundGroupRealtimeLeaderboard']
  ) => Promise<
    inferRouterOutputs<CardrunRouter>['cardrunGetRoundGroupRealtimeLeaderboard']
  >;
  cardrunGetRoundLeaderboard: (
    input: inferRouterInputs<CardrunRouter>['cardrunGetRoundLeaderboard']
  ) => Promise<inferRouterOutputs<CardrunRouter>['cardrunGetRoundLeaderboard']>;
  cardrunGetRoundGroupLeaderboard: (
    input: inferRouterInputs<CardrunRouter>['cardrunGetRoundGroupLeaderboard']
  ) => Promise<
    inferRouterOutputs<CardrunRouter>['cardrunGetRoundGroupLeaderboard']
  >;
  cardrunAdminFillData: () => Promise<
    inferRouterOutputs<CardrunRouter>['cardrunAdminFillData']
  >;
  cardrunGetCardStatistics: () => Promise<
    inferRouterOutputs<CardrunRouter>['cardrunGetCardStatistics']
  >;
  cardrunGetCards: () => Promise<
    inferRouterOutputs<CardrunRouter>['cardrunGetUserCards']
  >;
  cardrunGetPacks: () => Promise<
    inferRouterOutputs<CardrunRouter>['cardrunGetUserPacks']
  >;
  cardrunStart: (
    input: inferRouterInputs<CardrunRouter>['cardrunUserStart']
  ) => Promise<inferRouterOutputs<CardrunRouter>['cardrunUserStart']>;
  /**
   * Patron
   **/
  patron: {
    getEarlyAccessConfigByCode: (
      input: inferRouterInputs<PatronRouter>['getEarlyAccessConfigByCode']
    ) => Promise<
      inferRouterOutputs<PatronRouter>['getEarlyAccessConfigByCode']
    >;
    getCurrentRates: (
      input: inferRouterInputs<PatronRouter>['getCurrentRates']
    ) => Promise<inferRouterOutputs<PatronRouter>['getCurrentRates']>;
    claimEarlyAccessCode: (
      input: inferRouterInputs<PatronRouter>['claimEarlyAccessCode']
    ) => Promise<inferRouterOutputs<PatronRouter>['claimEarlyAccessCode']>;
    getEarlyAccessConfig: () => Promise<
      inferRouterOutputs<PatronRouter>['getEarlyAccessConfig']
    >;
    getConfig: () => Promise<
      inferRouterOutputs<PatronRouter>['patronGetConfig']
    >;
    getReceipts: () => Promise<
      inferRouterOutputs<PatronRouter>['patronGetReceipts']
    >;
    getReservations: () => Promise<
      inferRouterOutputs<PatronRouter>['patronGetReservations']
    >;
    purchase: (
      input: inferRouterInputs<PatronRouter>['patronPurchase']
    ) => Promise<inferRouterOutputs<PatronRouter>['patronPurchase']>;
    purchaseGp: (
      input: inferRouterInputs<PatronRouter>['patronPurchaseGp']
    ) => Promise<inferRouterOutputs<PatronRouter>['patronPurchaseGp']>;
    transferAllocation: (
      input: inferRouterInputs<PatronRouter>['patronTransferAllocation']
    ) => Promise<inferRouterOutputs<PatronRouter>['patronTransferAllocation']>;
    getCommunityPatronsUsernames: () => Promise<
      inferRouterOutputs<PatronRouter>['getCommunityPatronsUsernames']
    >;
    getGpRedemptions: () => Promise<
      inferRouterOutputs<PatronRouter>['patronGetGpRedemptions']
    >;
    gpRedeem: (
      input: inferRouterInputs<PatronRouter>['patronGpRedeem']
    ) => Promise<inferRouterOutputs<PatronRouter>['patronGpRedeem']>;
  };
  gift: {
    claimGift: (
      input: inferRouterInputs<PatronRouter>['claimGift']
    ) => Promise<inferRouterOutputs<PatronRouter>['claimGift']>;
    getGift: (
      input: inferRouterInputs<PatronRouter>['getGift']
    ) => Promise<inferRouterOutputs<PatronRouter>['getGift']>;
  };
  /**
   * Patron Claim
   **/
  patronClaim: {
    getNonce: () => Promise<inferRouterOutputs<PatronClaimRouter>['getNonce']>;
    getSession: () => Promise<
      inferRouterOutputs<PatronClaimRouter>['getSession']
    >;
    signout: () => Promise<inferRouterOutputs<PatronClaimRouter>['signout']>;
    verifySiweMessage: (
      input: inferRouterInputs<PatronClaimRouter>['verifySiweMessage']
    ) => Promise<inferRouterOutputs<PatronClaimRouter>['verifySiweMessage']>;
    getVestingSchedule: (
      input: inferRouterInputs<PatronClaimRouter>['getVestingSchedule']
    ) => Promise<inferRouterOutputs<PatronClaimRouter>['getVestingSchedule']>;
    getPatronPlatformClaims: (
      input: inferRouterInputs<PatronClaimRouter>['getPatronPlatformClaims']
    ) => Promise<
      inferRouterOutputs<PatronClaimRouter>['getPatronPlatformClaims']
    >;
    claim: (
      input: inferRouterInputs<PatronClaimRouter>['claimPatronPlatform']
    ) => Promise<inferRouterOutputs<PatronClaimRouter>['claimPatronPlatform']>;
  };

  /**
   * Recovery
   **/
  recovery: {
    getAuthInfo: (
      input: inferRouterInputs<RecoveryRouter>['getAuthInfo']
    ) => Promise<inferRouterOutputs<RecoveryRouter>['getAuthInfo']>;
    getRecoverableUsers: (
      input: inferRouterInputs<RecoveryRouter>['getRecoverableUsers']
    ) => Promise<inferRouterOutputs<RecoveryRouter>['getRecoverableUsers']>;
    getRecoveries: (
      input: inferRouterInputs<RecoveryRouter>['getRecoveries']
    ) => Promise<inferRouterOutputs<RecoveryRouter>['getRecoveries']>;
    getSignatureChallenge: (
      input: inferRouterInputs<RecoveryRouter>['getSignatureChallenge']
    ) => Promise<inferRouterOutputs<RecoveryRouter>['getSignatureChallenge']>;
    recoverEvm: (
      input: inferRouterInputs<RecoveryRouter>['recoverEvm']
    ) => Promise<inferRouterOutputs<RecoveryRouter>['recoverEvm']>;
    recoverSolana: (
      input: inferRouterInputs<RecoveryRouter>['recoverSolana']
    ) => Promise<inferRouterOutputs<RecoveryRouter>['recoverSolana']>;
    recoverEvmNft: (
      input: inferRouterInputs<RecoveryRouter>['recoverEvmNft']
    ) => Promise<inferRouterOutputs<RecoveryRouter>['recoverEvmNft']>;
    recoveryAppLogin: (
      input: inferRouterInputs<RecoveryRouter>['recoveryAppLogin']
    ) => Promise<inferRouterOutputs<RecoveryRouter>['recoveryAppLogin']>;
    recoveryAppLogout: (
      input: inferRouterInputs<RecoveryRouter>['recoveryAppLogout']
    ) => Promise<inferRouterOutputs<RecoveryRouter>['recoveryAppLogout']>;
    recoverPatronNfts: (
      input: inferRouterInputs<RecoveryRouter>['recoverPatronNfts']
    ) => Promise<inferRouterOutputs<RecoveryRouter>['recoverPatronNfts']>;
  };
  /**
   * Account
   **/
  account: {
    healthCheck: () => Promise<{
      api: PromiseSettledResult<unknown>;
      subscriptions: PromiseSettledResult<unknown>;
    }>;
    state: () => Promise<inferRouterOutputs<AccountsAppRouter>['state']>;
    events: (opts: {
      onData: (value: Event) => void;
      onError?: (err: Error) => void;
    }) => Promise<Unsubscribable>;
  };
  /**
   * Airdrops
   **/
  airdrop: {
    claim: (
      input: inferRouterInputs<AirdropRouter>['airdropClaim']
    ) => Promise<inferRouterOutputs<AirdropRouter>['airdropClaim']>;
    list: (
      input: inferRouterInputs<AirdropRouter>['airdropList']
    ) => Promise<inferRouterOutputs<AirdropRouter>['airdropList']>;
    getGpDistributions: () => Promise<
      inferRouterOutputs<AirdropRouter>['getGpDistributions']
    >;
  };
  /**
   * Send
   **/
  send: {
    sendEvm: (
      input: inferRouterInputs<WithdrawRouter>['sendEvm']
    ) => Promise<inferRouterOutputs<WithdrawRouter>['sendEvm']>;

    sendEvmNft: (
      input: inferRouterInputs<WithdrawRouter>['sendEvmNft']
    ) => Promise<inferRouterOutputs<WithdrawRouter>['sendEvmNft']>;

    sendSolana: (
      input: inferRouterInputs<WithdrawRouter>['sendSolana']
    ) => Promise<inferRouterOutputs<WithdrawRouter>['sendSolana']>;
  };

  actions: {
    list: (
      input: inferRouterInputs<ActionRouter>['getActions']
    ) => Promise<inferRouterOutputs<ActionRouter>['getActions']>;
  };

  swidge: {
    quote: (
      input: inferRouterInputs<SwidgeRouter>['swidgeQuote']
    ) => Promise<inferRouterOutputs<SwidgeRouter>['swidgeQuote']>;
    submit: (
      input: inferRouterInputs<SwidgeRouter>['swidgeSubmit']
    ) => Promise<inferRouterOutputs<SwidgeRouter>['swidgeSubmit']>;
  };
};

/**
 * TODO: clean up this entire module.
 *
 * _most_ of this module is a thin wrapper around the tRPC client itself,
 * so we may be able to move the bespoke logic into utilities and just leverage
 * the underlying client instance(s) directly.
 *
 * Investigate https://trpc.io/docs/client/react
 **/
export function createClient(config: {
  fetch?: HTTPBatchLinkOptions['fetch'];
  rpId: string;
  url: string;
  WebSocket?: typeof WebSocket;
  wsUrl: string;
  env: Environment;
  pollingInterval?: number;
  chains?: {
    arbitrum: { rpcUrl: string | undefined };
    base: { rpcUrl: string | undefined };
    blast: { rpcUrl: string | undefined };
    ethereum: { rpcUrl: string | undefined };
    optimism: { rpcUrl: string | undefined };
    polygon: { rpcUrl: string | undefined };
    solana: { rpcUrl: string | undefined };
  };
  chainAssetOverrides?: Record<ChainAssetId, Partial<ChainAsset>>;
}): Client {
  const publicClient = createPublicClient({
    env: config.env,
    chains: config.chains,
    pollingInterval: config.pollingInterval ?? 10000,
    chainAssetOverrides: config.chainAssetOverrides,
  });

  const mainTrpcClient = createTRPCProxyClient({
    links: [
      splitLink({
        condition(op) {
          return op.type === 'subscription';
        },
        true: wsLink({
          client: createWSClient({
            url: config.wsUrl,
            WebSocket: config.WebSocket ?? (IsomorphicWebSocket as any),
          }),
        }),
        false: splitLink({
          condition(op) {
            return NON_BATCH_PATHS.includes(op.path);
          },
          true: httpLink({
            url: config.url,
            fetch: (url, opts) =>
              (config.fetch ?? fetch)(url, { ...opts, credentials: 'include' }),
          }),
          false: httpBatchLink({
            url: config.url,
            fetch: (url, opts) =>
              (config.fetch ?? fetch)(url, { ...opts, credentials: 'include' }),
          }),
        }),
      }),
    ],
  });

  // Having a single router + client (even if it's a merge of sub-routers)
  // results in significantly slower type inference.
  //
  // Hence, we distinctly typed versions of the same client instance for each router.
  const routerTrpcClient = <T extends AnyRouter>() =>
    mainTrpcClient as unknown as CreateTRPCProxyClient<T>;

  const cardrunClient = routerTrpcClient<CardrunRouter>();
  const recoveryClient = routerTrpcClient<RecoveryRouter>();
  const patronClient = routerTrpcClient<PatronRouter>();
  const patronClaimClient = routerTrpcClient<PatronClaimRouter>();
  const curveClient = routerTrpcClient<CurveRouter>();
  const authClient = routerTrpcClient<AuthRouter>();
  const healthClient = routerTrpcClient<HealthRouter>();
  const withdrawClient = routerTrpcClient<WithdrawRouter>();
  const actionClient = routerTrpcClient<ActionRouter>();
  const bridgeClient = routerTrpcClient<BridgeRouter>();
  const statsClient = routerTrpcClient<StatsRouter>();
  const assetClient = routerTrpcClient<AssetRouter>();
  const contractClient = routerTrpcClient<ContractRouter>();
  const airdropClient = routerTrpcClient<AirdropRouter>();
  const swidgeClient = routerTrpcClient<SwidgeRouter>();

  const getAccountTrpcClient = (() => {
    let accountTrpcClient: ReturnType<
      typeof createTRPCProxyClient<AccountsAppRouter>
    >;

    return async function () {
      if (accountTrpcClient) return accountTrpcClient;

      accountTrpcClient = createTRPCProxyClient<AccountsAppRouter>({
        links: [
          wsLink({
            client: createWSClient({
              url: `${config.wsUrl}/_account`,
              WebSocket: config.WebSocket ?? (IsomorphicWebSocket as any),
            }),
          }),
        ],
      });

      return accountTrpcClient;
    };
  })();

  async function getInngestRunOutput(
    input: inferRouterInputs<HealthRouter>['getInngestRuns']
  ) {
    let runs = await healthClient.getInngestRuns.query(input);
    while (runs[0]?.status !== 'Completed') {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      runs = await healthClient.getInngestRuns.query(input);
      if (runs[0]?.status === 'Failed' || runs[0]?.status === 'Cancelled') {
        throw new Error(`Function run ${runs[0].status}`);
      }
    }
    return runs[0].output;
  }

  return {
    async healthCheck() {
      const [
        api, // main worker
        subscriptions, // main worker websockets
        durableWorkflows, // inngest
      ] = await Promise.allSettled([
        (async () => {
          const result = await healthClient.healthCheck.query();
          return result;
        })(),
        (async () => {
          const result = await new Promise<string>(async (resolve, reject) => {
            const subscription = healthClient.timestamp.subscribe(undefined, {
              onData(data) {
                subscription.unsubscribe();
                resolve(data);
              },
              onError(err) {
                subscription.unsubscribe();
                reject(err);
              },
            });
          });
          return result;
        })(),
        (async () => {
          const event = await healthClient.inngestHealthCheck.query();
          const eventId = event.ids[0]!;
          const result = await getInngestRunOutput({ eventId });
          return result;
        })(),
      ]);

      return {
        api,
        subscriptions,
        durableWorkflows,
      };
    },

    async generateRegistrationOptions(userName: string) {
      const result = await authClient.generateRegistrationOptions.mutate({
        userName,
      });
      return result;
    },
    async generateAuthenticationOptions(userName: string) {
      return await authClient.generateAuthenticationOptions.mutate({
        userName,
      });
    },
    async signup(input) {
      const result = await authClient.signup.mutate(input);
      return result;
    },

    async login(input) {
      const result = await authClient.login.mutate(input);
      return result;
    },

    async logout() {
      await authClient.logout.mutate();
    },

    async createSessionKey(opts?) {
      const { user, passkey } = await authClient.getCurrentUser.query();

      if (!user.turnkeyOwnerUserId)
        throw new Error('User turnkey owner not found');
      if (!user.turnkeySubOrgId)
        throw new Error('User turnkey suborg not found');

      const turnkeySessionKey = await generateCompressedKeyPair();

      const now = Date.now();
      const expirationSeconds = 1 * 60 * 15; // 15 minutes
      const expiry = new Date(now + expirationSeconds * 1000).toISOString();

      const turnkeyClient = new TurnkeyClient(
        { baseUrl: 'https://api.turnkey.com' },
        opts?.stamper ??
          new WebauthnStamper({
            rpId: config.rpId,
            allowCredentials: [
              {
                id: Buffer.from(passkey.credentialId, 'base64'), // TODO: should be base64url
                type: 'public-key',
              },
            ],
          })
      );

      await createActivityPoller({
        client: turnkeyClient,
        requestFn: turnkeyClient.createApiKeys,
      })({
        type: 'ACTIVITY_TYPE_CREATE_API_KEYS',
        timestampMs: String(now),
        organizationId: user.turnkeySubOrgId,
        parameters: {
          userId: user.turnkeyOwnerUserId,
          apiKeys: [
            {
              apiKeyName: 'Session',
              publicKey: turnkeySessionKey.publicKey,
              expirationSeconds: String(expirationSeconds),
            },
          ],
        },
      });

      await authClient.setSessionKey.mutate({
        expirationSeconds,
        expiry,
        privateKey: turnkeySessionKey.privateKey,
        publicKey: turnkeySessionKey.publicKey,
      });
    },

    async getSessionKeyExpiry() {
      const result = await authClient.getSessionKeyExpiry.query();
      return result;
    },

    async updateUserEmail(email: string) {
      const result = await authClient.updateUserEmail.mutate({ email });
      return result;
    },

    async verifyUserEmail(verificationCode: string) {
      const result = await authClient.verifyUserEmail.mutate({
        emailVerificationCode: verificationCode,
      });
      return result;
    },

    async getCurrentUser() {
      const result = await authClient.getCurrentUser.query();
      return result;
    },

    async getUsernameSuggestions() {
      return await authClient.getUsernameSuggestions.query();
    },

    async checkUsernameAvailability(input) {
      const result = await authClient.checkUsernameAvailability.query(input);
      return result;
    },

    async checkReferralCodeValidity(input) {
      const result = await authClient.checkReferralCodeValidity.query(input);
      return result;
    },

    async getLeaderboardStats() {
      return await statsClient.getLeaderboardStats.query();
    },

    async getPlatformStats() {
      return await statsClient.getPlatformStats.query();
    },

    async addRecoveryKey(input) {
      return await contractClient.addRecoveryKey.mutate(input);
    },

    async addFundsRecoveryAddress(input) {
      return await contractClient.addFundsRecoveryAddress.mutate(input);
    },

    async getSupportedChainAssets() {
      const result = await assetClient.getSupportedChainAssets.query();
      return result;
    },

    async syncAssetConfig() {
      return await assetClient.syncAssetConfig.mutate();
    },

    async getUserBalances() {
      const result = await assetClient.getUserBalances.query();
      return result;
    },

    async getAssetRates() {
      const result = await assetClient.getAssetRates.query();
      return result;
    },

    async getWaitlistValues() {
      const result = await statsClient.getWaitlistValues.query();
      return result;
    },

    async ctpGetValues(input) {
      return await statsClient.ctpGetValues.query(input);
    },

    async refreshAssetRates() {
      return await assetClient.refreshAssetRates.mutate();
    },

    async withdrawEvm(input) {
      return await withdrawClient.withdrawEvm.mutate(input);
    },

    async withdrawEvmNft(input) {
      return await withdrawClient.withdrawEvmNft.mutate(input);
    },

    async withdrawSolana(input) {
      return await withdrawClient.withdrawSolana.mutate(input);
    },

    async getWithdrawalAddresses() {
      return await withdrawClient.getWithdrawalAddresses.query();
    },

    async deleteWithdrawalAddress(input) {
      return await withdrawClient.deleteWithdrawalAddress.mutate(input);
    },

    async updateWithdrawalAddress(input) {
      return await withdrawClient.updateWithdrawalAddress.mutate(input);
    },

    async addWithdrawalAddress(input) {
      return await withdrawClient.addWithdrawalAddress.mutate(input);
    },

    async bridge(input) {
      return await bridgeClient.bridge.mutate(input);
    },

    async requiresSessionKey() {
      const sessionKeyExpiry = await this.getSessionKeyExpiry();
      if (
        !sessionKeyExpiry ||
        Date.now() + 5 * 60 * 1000 >= new Date(sessionKeyExpiry).getTime()
      ) {
        await this.createSessionKey();
      }
    },

    async upgradeEvm(input) {
      return await contractClient.upgradeEvm.mutate(input);
    },

    async upgradeSolana(input) {
      return await contractClient.upgradeSolana.mutate(input);
    },

    async deployEvmAppAccount(input) {
      return await contractClient.deployEvmAppAccount.mutate(input);
    },

    async curveSwap(input) {
      return await curveClient.curveSwap.mutate(input);
    },

    async curveQuote(input) {
      return await curveClient.curveQuote.query(input);
    },

    async curveGetPools(input) {
      return await curveClient.curveGetPools.query(input);
    },

    async curveGetSwaps() {
      return await curveClient.curveGetSwaps.query();
    },

    async getBridges() {
      return await bridgeClient.getBridges.query();
    },

    async getWithdrawals() {
      return await withdrawClient.getWithdrawals.query();
    },

    async getUpgrades() {
      return await contractClient.getUpgrades.query();
    },

    async getEvmAppAccounts() {
      return await contractClient.getEvmAppAccounts.query();
    },

    async createSupportTicket(input) {
      return await authClient.createSupportTicket.mutate(input);
    },

    oauth: {
      async twitterGenerateOAuthLink() {
        const result = await authClient.twitterGenerateOAuthLink.query();

        return result;
      },

      async twitterOAuthToken(input) {
        const result = await authClient.twitterOAuthToken.mutate(input);

        return result;
      },
    },

    totp: {
      async makeTotp(input) {
        return authClient.makeTotp.mutate(input);
      },
      async enrolTotp(input) {
        return authClient.enrolTotp.mutate(input);
      },
      async authTotp(input) {
        return authClient.authTotp.mutate(input);
      },
    },

    gift: {
      async claimGift(input) {
        return patronClient.claimGift.mutate(input);
      },
      async getGift(input) {
        return patronClient.getGift.query(input);
      },
    },

    patron: {
      async getEarlyAccessConfigByCode(input) {
        return patronClient.getEarlyAccessConfigByCode.query(input);
      },

      async getCurrentRates(input) {
        return patronClient.getCurrentRates.query(input);
      },

      async claimEarlyAccessCode(input) {
        return patronClient.claimEarlyAccessCode.mutate(input);
      },

      async getEarlyAccessConfig() {
        return patronClient.getEarlyAccessConfig.query();
      },

      async getConfig() {
        return patronClient.patronGetConfig.query();
      },

      async getReservations() {
        return patronClient.patronGetReservations.query();
      },

      async getReceipts() {
        return patronClient.patronGetReceipts.query();
      },

      async purchase(input) {
        return await patronClient.patronPurchase.mutate(input);
      },

      async purchaseGp(input) {
        return await patronClient.patronPurchaseGp.mutate(input);
      },

      async transferAllocation(input) {
        return await patronClient.patronTransferAllocation.mutate(input);
      },

      async getCommunityPatronsUsernames() {
        return await patronClient.getCommunityPatronsUsernames.query();
      },

      async gpRedeem(input) {
        return await patronClient.patronGpRedeem.mutate(input);
      },

      async getGpRedemptions() {
        return await patronClient.patronGetGpRedemptions.query();
      },
    },

    async cardrunGetRounds() {
      return await cardrunClient.cardrunGetRounds.query();
    },

    async cardrunGetMinimumBalance() {
      return await cardrunClient.cardrunGetMinimumBalance.query();
    },

    async cardrunGetCards() {
      return await cardrunClient.cardrunGetUserCards.query();
    },

    async cardrunGetLatestRoundPlays(input) {
      return await cardrunClient.cardrunGetLatestRoundPlays.query(input);
    },

    async cardrunGetPacks() {
      return await cardrunClient.cardrunGetUserPacks.query();
    },

    async cardrunLockCard(input) {
      return await cardrunClient.cardrunLockCard.mutate(input);
    },

    async cardrunGetRealtimePlayChart(input) {
      return await cardrunClient.cardrunGetRealtimePlayChart.query(input);
    },

    async cardrunGetPlayChart(input) {
      return await cardrunClient.cardrunGetPlayChart.query(input);
    },

    async cardrunGetStagedPlay(input) {
      return await cardrunClient.cardrunGetStagedPlay.query(input);
    },

    async cardrunOpenPack(input) {
      return await cardrunClient.cardrunOpenPack.mutate(input);
    },

    async cardrunStart() {
      return await cardrunClient.cardrunUserStart.mutate();
    },

    async cardrunPlayRound(input) {
      return await cardrunClient.cardrunPlayRound.mutate(input);
    },

    async cardrunGetRoundRealtimeRates(input) {
      return await cardrunClient.cardrunGetRoundRealtimeRates.query(input);
    },

    async cardrunGetRoundRealtimeLeaderboard(input) {
      return await cardrunClient.cardrunGetRoundRealtimeLeaderboard.query(
        input
      );
    },

    async cardrunGetUserStatistics(input) {
      return await cardrunClient.cardrunGetUserStatistics.query(input);
    },

    async cardrunGetRoundGroupRealtimeLeaderboard(input) {
      return await cardrunClient.cardrunGetRoundGroupRealtimeLeaderboard.query(
        input
      );
    },

    async cardrunGetRoundLeaderboard(input) {
      return await cardrunClient.cardrunGetRoundLeaderboard.query(input);
    },

    async cardrunGetRoundGroupLeaderboard(input) {
      return await cardrunClient.cardrunGetRoundGroupLeaderboard.query(input);
    },

    async cardrunGetCardStatistics() {
      return await cardrunClient.cardrunGetCardStatistics.query();
    },

    /**
     * @deprecated
     **/
    async cardrunAdminFillData() {
      return await cardrunClient.cardrunAdminFillData.mutate();
    },

    recovery: {
      async getSignatureChallenge(input) {
        return await recoveryClient.getSignatureChallenge.query(input);
      },

      async getAuthInfo(input) {
        return await recoveryClient.getAuthInfo.query(input);
      },

      async getRecoverableUsers(input) {
        return await recoveryClient.getRecoverableUsers.query(input);
      },

      async getRecoveries(input) {
        return await recoveryClient.getRecoveries.query(input);
      },

      async recoveryAppLogin(input) {
        return await recoveryClient.recoveryAppLogin.mutate(input);
      },

      async recoveryAppLogout(input) {
        return await recoveryClient.recoveryAppLogout.mutate(input);
      },

      async recoverEvm(input) {
        return await recoveryClient.recoverEvm.mutate(input);
      },

      async recoverSolana(input) {
        return await recoveryClient.recoverSolana.mutate(input);
      },

      async recoverEvmNft(input) {
        return await recoveryClient.recoverEvmNft.mutate(input);
      },

      async recoverPatronNfts(input) {
        return await recoveryClient.recoverPatronNfts.mutate(input);
      },
    },

    patronClaim: {
      async getNonce() {
        return await patronClaimClient.getNonce.query();
      },
      async getSession() {
        return await patronClaimClient.getSession.query();
      },
      async signout() {
        return await patronClaimClient.signout.mutate();
      },
      async verifySiweMessage(input) {
        return await patronClaimClient.verifySiweMessage.mutate(input);
      },
      async getVestingSchedule(input) {
        return await patronClaimClient.getVestingSchedule.query(input);
      },
      async getPatronPlatformClaims(input) {
        return await patronClaimClient.getPatronPlatformClaims.query(input);
      },
      async claim(input) {
        return await patronClaimClient.claimPatronPlatform.mutate(input);
      },
    },

    account: {
      async healthCheck() {
        const accountTrpcClient = await getAccountTrpcClient();

        const [
          api, // accounts durable object
          subscriptions, // accounts durable object websockets
        ] = await Promise.allSettled([
          (async () => {
            const result = await accountTrpcClient.healthCheck.query();
            return result;
          })(),
          (async () => {
            const result = await new Promise<string>(
              async (resolve, reject) => {
                const subscription = accountTrpcClient.timestamp.subscribe(
                  undefined,
                  {
                    onData(data) {
                      subscription.unsubscribe();
                      resolve(data);
                    },
                    onError(err) {
                      subscription.unsubscribe();
                      reject(err);
                    },
                  }
                );
              }
            );
            return result;
          })(),
        ]);

        return {
          api,
          subscriptions,
        };
      },

      async state() {
        const accountTrpcClient = await getAccountTrpcClient();
        const result = await accountTrpcClient.state.query();
        return result;
      },

      async events(opts: {
        onData: (value: Event) => void;
        onError?: (err: Error) => void;
      }) {
        const accountTrpcClient = await getAccountTrpcClient();
        const subscription = accountTrpcClient.events.subscribe(
          undefined,
          opts
        );
        return subscription;
      },
    },

    airdrop: {
      async claim(input) {
        return airdropClient.airdropClaim.mutate(input);
      },

      async list(input) {
        return airdropClient.airdropList.query(input);
      },

      async getGpDistributions() {
        return airdropClient.getGpDistributions.query();
      },
    },

    send: {
      async sendEvm(input) {
        return await withdrawClient.sendEvm.mutate(input);
      },
      async sendEvmNft(input) {
        return await withdrawClient.sendEvmNft.mutate(input);
      },
      async sendSolana(input) {
        return await withdrawClient.sendSolana.mutate(input);
      },
    },

    actions: {
      async list(input) {
        return actionClient.getActions.query(input);
      },
    },

    swidge: {
      async quote(input) {
        return swidgeClient.swidgeQuote.query(input);
      },

      async submit(input) {
        return swidgeClient.swidgeSubmit.mutate(input);
      },
    },
  };
}

function stringify(obj: any) {
  return JSON.stringify(obj, (key, value) =>
    typeof value === 'bigint' ? value.toString() : value
  );
}
